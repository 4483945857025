import HelperMixin from '../../../Shared/HelperMixin.vue'
export default {
    name: 'AppFooter',
    mixins: [HelperMixin],
    created() { 

        
    },
    data() {
        return {            
        };
    },
  
    methods: {
      
    }    
}
